import React from 'react';
import PropTypes from 'prop-types';

export const Picture = ({
  srcSet,
  className = null,
  style = null,
  alt = null,
  loading = 'eager',
  'data-qa-id': dataQaId = null,
  width,
  height,
}) => {
  const srcSetArray = Array.isArray(srcSet) ? srcSet : [srcSet];
  return (
    <picture
      id={className}
      className={className ? `picture__${className}` : null}
      style={style || null}
    >
      {srcSetArray.slice(0, -1).map((src) => (
        <source key={src} srcSet={src} type={`image/${src.substring(src.lastIndexOf('.') + 1)}`} />
      ))}
      <img
        src={srcSetArray[srcSetArray.length - 1]}
        className={className || null}
        style={style || null}
        alt={alt || null}
        loading={loading}
        data-qa-id={dataQaId || null}
        width={width}
        height={height}
      />
    </picture>
  );
};

Picture.propTypes = {
  srcSet: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  'data-qa-id': PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  alt: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  loading: PropTypes.oneOf(['eager', 'lazy']),
  width: PropTypes.number,
  height: PropTypes.number,
};
