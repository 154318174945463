import {forwardRef, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

export const DEFAULT_VIDEO_OPTIONS = {
  autoPlay: true,
  loop: true,
  muted: true,
  playsInline: true,
};

export const Video = forwardRef(
  (
    {
      src,
      className = '',
      options = DEFAULT_VIDEO_OPTIONS,
      style = null,
      reload = false,
      onClick = () => {},
      onCanPlay = () => {},
      'data-qa-id': dataQaId = null,
      id = null,
      width,
      height,
    },
    videoRef
  ) => {
    const srcArray = Array.isArray(src) ? src : [src];

    // eslint-disable-next-line no-param-reassign
    if (!videoRef) videoRef = useRef(null);

    useEffect(() => {
      if (videoRef?.current && reload) {
        videoRef.current.load();
      }
    }, [srcArray]);

    useEffect(() => {
      if (videoRef?.current) {
        // eslint-disable-next-line no-param-reassign
        videoRef.current.defaultMuted = options.muted;
        videoRef.current.setAttribute('webkit-playsinline', options.playsInline);
      }
    }, []);

    return (
      /* eslint-disable-next-line jsx-a11y/media-has-caption */
      <video
        preload="auto"
        ref={videoRef}
        className={className}
        autoPlay={options.autoPlay}
        loop={options.loop}
        muted={options.muted}
        playsInline={options.playsInline}
        onClick={onClick}
        onCanPlay={onCanPlay}
        style={style}
        data-qa-id={dataQaId}
        id={id}
        width={width}
        height={height}
        key={id || src}
      >
        {srcArray.map((videoSrc) => (
          <source
            key={videoSrc}
            src={videoSrc}
            type={`video/${videoSrc.substring(videoSrc.lastIndexOf('.') + 1)}`}
          />
        ))}
        Your browser does not support the video tag.
      </video>
    );
  }
);

Video.propTypes = {
  src: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired,
  className: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.objectOf(DEFAULT_VIDEO_OPTIONS),
    PropTypes.oneOf([null]),
  ]),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  reload: PropTypes.bool,
  onClick: PropTypes.func,
  onCanPlay: PropTypes.func,
};
